import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Section, Wysiwyg, Container } from 'src/components/Base'
import { Hero, Logos, BestPracticeCards } from 'src/sections'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import Seo from 'src/components/Seo'

const BestPracticeOverview = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspBestPracticeOverview.bestPracticesIntro
  const contact = page.atspBestPracticeOverview.bestPracticeContact
  const logos = page.atspBestPracticeOverview.bestPracticeLogos
  const bestPracticeCards = page.atspBestPracticeOverview.bestPracticesRepeater

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.text}</Wysiwyg>
        </Container>
      </Section>
      <BestPracticeCards cardsArray={bestPracticeCards} />
      <SectionTwoColumns
        hasBackground
        centerMobile
        contentLeft={
          <Heading as="h2" color="dark" size={2}>
            {contact.heading}
          </Heading>
        }
        contentRight={<Wysiwyg>{contact.text}</Wysiwyg>}
        rightColumnRemoveDefaultMarginOnMobile
        buttonRight
        buttonText={contact.button.text}
        buttonLink={contact.button.link.uri}
        divider
      />
      <Logos heading={logos.heading} logos={logos.repeater} />
    </>
  )
}

export const pageQuery = graphql`
  query bestPracticeOverview($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      atspBestPracticeOverview {
        bestPracticeContact {
          heading
          text
          button {
            link {
              ... on WpPage {
                uri
              }
            }
            text
          }
        }
        bestPracticeLogos {
          heading
          repeater {
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        bestPracticesIntro {
          heading
          text
        }
        bestPracticesRepeater {
          cardHeading
          cardText
          cardLink {
            target
            title
            url
          }
          cardHeaderImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          customerLogo {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
export default BestPracticeOverview
